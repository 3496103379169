import React, { useState } from 'react'
import { Alert } from 'react-native'
import { supabase } from '../components/initSupabase'
import { Button, Input, Card, Center, Text, Icon, Column } from '../components';
import FontAwesome from '@expo/vector-icons/FontAwesome';

export default function Auth() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState('')

  const handleLogin = async (type: string, email: string, password: string) => {
    console.log('handleLogin', type, email, password);
    setLoading(type)
    const {
      data: { user },
      error,
    } = type === 'LOGIN'
        ? await supabase.auth.signInWithPassword({ email, password })
        : await supabase.auth.signUp({ email, password })
    if (!error && !user) Alert.alert('Check your email for the login link!')
    if (error) Alert.alert(error.message)
    setLoading('')
  }

  return (
    <Center>
      <Card>
        <Column>
          <Text><Icon as={FontAwesome} name="envelope" size="5" />Email</Text>
          <Input
            onChangeText={(text) => setEmail(text)}
            value={email}
            placeholder="email@address.com"
            autoCapitalize={'none'}
          />
          <Text><Icon as={FontAwesome} name="lock" size="5" />Password</Text>
          <Input
            onChangeText={(text) => setPassword(text)}
            value={password}
            secureTextEntry={true}
            placeholder="Password"
            autoCapitalize={'none'}
          />
          <Button
            title="Sign in"
            disabled={!!loading.length}
            loading={loading === 'LOGIN'}
            onPress={() => handleLogin('LOGIN', email, password)}
          ><Text>Sign in</Text></Button>
          <Button
            disabled={!!loading.length}
            loading={loading === 'SIGNUP'}
            onPress={() => handleLogin('SIGNUP', email, password)}
          ><Text>Sign up</Text></Button>
        </Column>
      </Card>
    </Center>
  )
}