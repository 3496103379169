import { AppBase, Heading, StatusBar, TopBar } from "bobst";
import Navigation from "./navigation";

export default function App() {
  return (
    <AppBase>
      <TopBar logo={require('./assets/favicon.png')} hamburger><Heading>Otta</Heading></TopBar>
      <Navigation />
      <StatusBar style="auto" />
    </AppBase>
  )
}
