import React from 'react';
import {StyleSheet, SafeAreaView, View, Text} from 'react-native';

import Animated, {
  useAnimatedProps,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';
import {curveBasis, line, scaleLinear, scaleTime} from 'd3';
import {G, Line, Path, Svg} from 'react-native-svg';
import {mixPath, ReText, parse} from 'react-native-redash';

const AnimatedPath = Animated.createAnimatedComponent(Path);

const makeGraph = (data, height, width) => {
    const max = Math.max(...data.map(val => val.value));
    const min = Math.min(...data.map(val => val.value));
    const y = scaleLinear().domain([0, max]).range([height, 35]);
  
    const x = scaleTime()
      .domain([new Date(2000, 1, 1), new Date(2000, 1, 15)])
      .range([10, width - 10]);
  
    const curvedLine = line()
      .x(d => x(new Date(d.date)))
      .y(d => y(d.value))
      .curve(curveBasis)(data);
  
    return {
      max,
      min,
      curve: parse(curvedLine),
      mostRecent: data[data.length - 1].value,
    };
  };

const RingChart = ({
  height,
  width,
  data, // [makeGraph([])]
  bottomPadding,
  leftPadding,
  title,
}) => {
  const selectedGraph = useSharedValue(makeGraph(data, height, width));
  const previousGraph = useSharedValue({...makeGraph(data, height, width)});
  const transition = useSharedValue(1);

  const animatedProps = useAnimatedProps(() => {
    return {
      d: mixPath(
        transition.value,
        previousGraph.value.curve,
        selectedGraph.value.curve,
      ),
    };
  });

  const mostRecent = useDerivedValue(() => {
    return `$${selectedGraph.value.mostRecent}`;
  });

  return (
    <SafeAreaView style={styles.container}>
      {title ?
        <View style={styles.titleContainer}>
            <Text style={styles.titleText}>{title}</Text>
            <ReText style={styles.priceText} text={mostRecent} />
        </View> : null}
      <Animated.View style={styles.chartContainer}>
        <Svg width={width} height={height} stroke="#6231ff">
          <G y={-bottomPadding}>
            <AnimatedPath animatedProps={animatedProps} strokeWidth="2" />
          </G>
        </Svg>
      </Animated.View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chartContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 12,
    marginHorizontal: 30,
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    color: 'black',
  },
  priceText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default RingChart;