import React, { useState } from "react";
// https://www.npmjs.com/package/react-datepicker
import { default as WebDatePicker } from "react-datepicker";
// https://www.npmjs.com/package/@react-native-community/datetimepicker
import { default as MobileDatePicker } from '@react-native-community/datetimepicker';
import { Platform } from 'react-native';

import "react-datepicker/dist/react-datepicker.css";

export default function DatePicker(props) {
  if (Platform.OS === 'web') {
    return <WebDatePicker {...props} />;
  }
  return <MobileDatePicker {...props} />;
};