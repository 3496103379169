import React from 'react';
import { Select, CheckIcon } from "native-base";

const toKebabCase = (str) => {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

const Dropdown = ({ options, selectedOption, selectedOptionIdx, setSelectedOptionIdx, placeholder }) => {
  if (selectedOption) {
    selectedOptionIdx = options.indexOf(selectedOption);
  }
  const values = options.map((option) => toKebabCase(option));
  const valueIdxMap = values.reduce((acc, val, i) => { acc[val] = i; return acc; }, {});
  return (
    <Select
      selectedValue={values[selectedOptionIdx]}
      minWidth="200"
      accessibilityLabel={placeholder}
      placeholder={placeholder}
      _selectedItem={{
        bg: "teal.600",
        endIcon: <CheckIcon size="5" />
      }}
      mt={1}
      onValueChange={itemValue => setSelectedOptionIdx(valueIdxMap[itemValue])}>
        {options.map((option, i) => (<Select.Item label={option} value={values[i]} />))}
    </Select>
  );
}

export default Dropdown;
