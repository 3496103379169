import React from 'react';
import { StyleSheet } from 'react-native';
import Pressable from "./Pressable";
import tw from 'twrnc';

export default function Button(props) {
  return (
    <Pressable
      {...props}
      style={tw`rounded-lg border border-gray-700 bg-gray-700 px-5 py-2.5 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-gray-900 hover:bg-gray-900 focus:ring focus:ring-gray-200 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-300 w-full`}>
        {(isHovered, isFocused, isPressed) => props.children}
    </Pressable>
  );
}

const style = StyleSheet.create({
  gradient: {
    default: {
      alignItems: 'center',
      backgroundImage: 'linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)',
      border: '0',
      borderRadius: '8px',
      boxShadow: 'rgba(151, 65, 252, 0.2) 0 15px 30px -5px',
      boxSizing: 'border-box',
      color: '#FFFFFF',
      display: 'flex',
      fontFamily: 'Phantomsans, sans-serif',
      fontSize: '20px',
      justifyContent: 'center',
      lineHeight: '1em',
      maxWidth: '100%',
      minWidth: '140px',
      padding: '19px 24px',
      textDecoration: 'none',
      userSelect: 'none',
      touchAction: 'manipulation',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    active: {
      outline: 0,
    },
    hover: {
      outline: 0,
    },
  },
  simpleWhite: {
    backgroundColor: '#fff',
    border: '0 solid #e2e8f0',
    borderRadius: '1.5rem',
    boxSizing: 'border-box',
    color: '#0d172a',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: "Basier circle",
    fontSize: '1.1rem',
    fontWeight: 'bold',
    lineHeight: '1',
    padding: '16px',
    margin: '4px',
    textAlign: 'center',
    textDecoration: 'none #0d172a solid',
    textDecorationThickness: 'auto',
    transition: 'all .1s cubic-bezier(.4, 0, .2, 1)',
    boxShadow: '0px 1px 2px rgba(166, 175, 195, 0.25)',
    userSelect: 'none',
    touchAction: 'manipulation',
  }
});