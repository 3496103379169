import React, { useEffect, useState, createContext, useContext } from 'react'
import { Session, User } from '@supabase/supabase-js'
import { supabase } from './initSupabase'

export const UserContext = createContext<{ user: User | null; session: Session | null }>({
  user: null,
  session: null,
})

export const UserContextProvider = (props: any) => {
  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const getAndSetSession = async () => {
      console.log('getAndSetSession');
      const {
        data: { session },
      } = await supabase.auth.getSession();
      console.log(`Setting session: ${JSON.stringify(session)}`);
      setSession(session);
      setUser(session?.user ?? null);
    };
    getAndSetSession().catch(console.error);
    const { data: { subscription: authListener } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log(`Supabase auth event: ${event}`)
      setSession(session)
      setUser(session?.user ?? null)
    })

    console.log('supabase: ', supabase);
    console.log('authListener: ', authListener);
    return () => {
      authListener.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = {
    session,
    user,
  }
  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context
}
