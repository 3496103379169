import React, { useEffect } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { PercentRingChart, Grid, Text, useUser, supabase, Button, Heading, Center, Icon, Row, Box, Divider, Container, TopVizBottomAction } from 'bobst';
import tw from 'twrnc';
import { useSips } from '../hooks/useSips';

type Cup = {
  id: number
  user_id: string
  oz: number
  created_at: Date
}

type Sip = {
  id: number
  user_id: string
  oz: number
  datetime: Date
  created_at: Date
}

const sum = (arr: Array<number>) => arr.reduce((a, b) => a + b, 0)

const VizComponent = (props) => <PercentRingChart height={300} width={300} data={props.ouncesToday / 64} bottomPadding={0} leftPadding={0} label={`${props.ouncesToday} oz`}/>;
const ActionComponent = (props) => (
    <Grid style={{ paddingTop: '64px', display: 'flex' }}>
        <Button onPress={() => props.addSip(props.WATER_CUP)}>
            <Row>
                <Icon as={MaterialCommunityIcons} name="cup" size="10" style={tw`text-white`} />
                <Text style={tw`font-medium text-white m-auto`}> {props.WATER_CUP.oz} oz</Text>
            </Row>
        </Button>
        <Button onPress={() => props.addSip(props.WATER_BOTTLE)}>
            <Row>
                <Icon as={MaterialCommunityIcons} name="bottle-soda-classic-outline" size="10" style={tw`text-white`} />
                <Text style={tw`font-medium text-white m-auto`}> {props.WATER_BOTTLE.oz} oz</Text>
            </Row>
        </Button>
        <Button onPress={() => props.addSip(props.TEA_CUP)}>
            <Row>
                <Icon as={MaterialCommunityIcons} name="tea" size="10" style={tw`text-white`} />
                <Text style={tw`font-medium text-white m-auto`}> {props.TEA_CUP.oz} oz</Text>
            </Row>
        </Button>
        <Button onPress={() => props.addSip(props.COFFEE_CUP)}>
            <Row>
                <Icon as={MaterialCommunityIcons} name="coffee" size="10" style={tw`text-white`} />
                <Text style={tw`font-medium text-white m-auto`}> {props.COFFEE_CUP.oz} oz</Text>
            </Row>
        </Button>
    </Grid>
);

export default function TrackScreen() {
  const { user } = useUser()
  const {sips, setSips} = useSips();

  useEffect(() => {
    fetchSips()
  }, []);

  const fetchSips = async () => {
    const { data: todos, error } = await supabase
      .from<Sip>('sips')
      .select('*')
      .order('id', { ascending: false })
    if (error) {
        console.log('error', error)
    } else {
        setSips(todos!)
    }
  }

  const addSip = async (cup: Cup) => {
    console.log('addSip', cup.oz, JSON.stringify(user));
    const { data: sip, error } = await supabase
      .from<Sip>('sips')
      .insert({ oz: cup.oz, user_id: user!.id })
      .select()
      .single()
    if (error) console.log(error.message)
    else {
      setSips([sip!, ...sips]);
    }
  }

  const isSameDateAs = function(lhsDate, rhsDate) {
    return (
        lhsDate.getFullYear() === rhsDate.getFullYear() &&
        lhsDate.getMonth() === rhsDate.getMonth() &&
        lhsDate.getDate() === rhsDate.getDate()
    );
  }

  const WATER_CUP = { id: 0, user_id: user!.id, oz: 8, created_at: new Date() } as Cup
  const WATER_BOTTLE = { id: 0, user_id: user!.id, oz: 16, created_at: new Date() } as Cup
  const TEA_CUP = { id: 0, user_id: user!.id, oz: 6, created_at: new Date() } as Cup
  const COFFEE_CUP = { id: 0, user_id: user!.id, oz: 8, created_at: new Date() } as Cup

  let ouncesToday = sum(sips.filter(sip => isSameDateAs(new Date(sip.datetime), new Date())).map(sip => sip.oz));

  return (
    <TopVizBottomAction
        vizComponent={<VizComponent {...{ouncesToday}} />}
        actionComponent={<ActionComponent {...{addSip, WATER_CUP, WATER_BOTTLE, TEA_CUP, COFFEE_CUP}} />} />
  );
}
