import React, { useState } from 'react'

type Sip = {
  id: number
  user_id: string
  oz: number
  datetime: Date
  created_at: Date
}

export function useSips() {
  const [sips, setSips] = useState<Array<Sip>>([]);
  return { sips, setSips };
}
