import React from 'react';
import {G, Text, Path, Svg} from 'react-native-svg';
import * as d3 from 'd3-shape';
import { useTheme } from 'native-base';

const percentArcConfig = (d) => d3.arc()({
    startAngle: 0,
    endAngle: d.endAngle - d.startAngle,
    innerRadius: width/2-10,
    outerRadius:  width/2-10,
    padAngle: 0.03,
    'stroke-linejoin': 'round',
    'stroke-linecap': 'round',
});

const makePie = (data, width, arcConfigFunction) => {
    return d3
        .pie()(data)
        .map(arcConfigFunction);
};

const RingChart = (props) => {
    const data = props.data ?? [1, 2, 3];
    const label = props.label ?? "";
    const width = props.width ?? 200;
    const height = props.height ?? 200;
    const themeColors = useTheme().colors;
    const colors = props.colors ?? [
        themeColors.primary['500'],
        themeColors.secondary['500'],
        themeColors.tertiary['500'],
        themeColors.primary['300'],
        themeColors.secondary['300'],
        themeColors.tertiary['300'],
        themeColors.primary['700'],
        themeColors.secondary['700'],
        themeColors.tertiary['700'],
    ];
    const arcConfigFunction = props.arcConfigFunction ?? ((d) => {
        return d3.arc()({
            startAngle: d.startAngle,
            endAngle: d.endAngle,
            innerRadius: 100,
            outerRadius:  width / 2 - 10,
            padAngle: 0.03,
        });
    });
    const pathStyleFunction = props.pathStyleFunction ?? ((d, i, colors) => ({
        stroke: colors[i] === 'transparent' ? '#ffffff' : colors[i],
        fill: 'none',
        opacity: colors[i] === 'transparent' ? '0.0' : '0.8',
        'stroke-linejoin': 'round',
        'stroke-linecap': 'round',
        'stroke-width': 20,
    }));

    const paths = makePie(data, width, arcConfigFunction).map((d, i) => {
        return (
            <Path key={i}
                d={d}
                id={i}
                style={pathStyleFunction(d, i, colors)} />);
    });

    const labelComponent = (<Text x={-25} y={0}>{ label ?? "" }</Text>);

    return (
        <Svg height={height}
            width={width}>
            <G transform={`translate(${height / 2},${width / 2})`}>
            { labelComponent }
            { paths }
            </G>
        </Svg>
    );
}

export default RingChart;