import { StyleSheet } from 'react-native';
import { StatusBar, Box, Row, Image, Icon } from '.';
import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';

const styles = StyleSheet.create({
  bar: {
    backgroundColor: 'white',
    height: '6em',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexItem: {
    margin: 'auto',
    flexGrow: 1,
    float: 'center',
  },
  left: {
    float: 'left',
  },
  right: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});

const FlexChild = (props) => {
  return <Box style={styles.flexItem}>{props.children}</Box>;
};

const LeftFlexChild = (props) => {
  return <FlexChild style={{...styles.flexItem, ...styles.left}}>{props.children}</FlexChild>;
};

const RightFlexChild = (props) => {
  console.log('right flex child', {...styles.flexItem, ...styles.right});
  return <FlexChild style={{...styles.flexItem, ...styles.right}}>{props.children}</FlexChild>;
};

const TopBar = (props) => {
    return <>
        <StatusBar bg="#3700B3" barStyle="light-content" />
        <Box safeAreaTop w="100%" />
        <Row style={styles.bar}>
            {props.logo ? (
                <LeftFlexChild><Image
                    source={props.logo}
                    alt="Logo"
                    size="sm"
                    resizeMode="contain"
                /></LeftFlexChild>) : null
            }
            {React.Children.map(props.children, (child, i) => {
                return <FlexChild key={i}>{child}</FlexChild>;
            })}
            {props.hamburger ? (
                <RightFlexChild>
                  <Icon
                    size="lg"
                    as={MaterialIcons}
                    name="menu"
                    color="black" />
                </RightFlexChild>) : null
            }
        </Row>
    </>;
};

export default TopBar;