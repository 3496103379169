import { View } from "..";

function Grid(props) {
    let gridWidth = props.width ?? 400;
    let nColumns = props.nColumns ?? 2;
    let columnWidth = gridWidth / nColumns;
    let padding = props.padding ?? 8;
    return (
        <View style={{
            ...styles.grid,
            width: gridWidth,
        }}>
            {props.children.map((child) => {
                return (<View style={{
                    ...styles.gridItem,
                    minWidth: columnWidth,
                    maxWidth: columnWidth,
                    padding: padding,
                }}>
                    {child}
                </View>);
            })}
        </View>
    );
}

const styles = {
    grid: {
      marginHorizontal: "auto",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    gridItem: {
      flex: 1,
    }
  };

export default Grid;