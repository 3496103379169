import React from 'react';
import { Pressable as NativeBasePressable, Box } from 'native-base';

export default function Pressable(props) {
  return <Box alignItems="center">
      <NativeBasePressable {...props}>
        {({
          isHovered,
          isFocused,
          isPressed
        }) => {
          return (
            <Box style={{
              transform: [{
                scale: isPressed ? 0.96 : 1
              }]
            }}>
              {props.children({
                isHovered,
                isFocused,
                isPressed
              })}
            </Box>
          );
        }}
      </NativeBasePressable>
    </Box>;
}
