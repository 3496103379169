import React from 'react';
import {StyleSheet} from 'react-native';
import {Column, Center} from '..';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '728px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  center: {
    margin: 'auto',
  }
});

export default function TopVizBottomAction({ vizComponent, actionComponent }) {
  return (
      <Column style={styles.container}>
        <Center >
          {vizComponent}
        </Center>
        {actionComponent}
      </Column>
  );
};