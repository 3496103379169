import React, { useEffect } from 'react'
import { Text, supabase, Button, FlatList, ScrollView, View, Heading, Center, Row, Divider, Container } from 'bobst';
import tw from 'twrnc';
import { useSips } from '../hooks/useSips';

type Sip = {
    id: number
    user_id: string
    oz: number
    datetime: Date
    created_at: Date
}

export default function HistoryScreen({ route, navigation }) {
    console.log('HistoryScreen', route, navigation);
    const {sips, setSips} = useSips();
    
    useEffect(() => {
        fetchSips()
    }, [])
    
    const fetchSips = async () => {
        const { data: todos, error } = await supabase
        .from<Sip>('sips')
        .select('*')
        .order('id', { ascending: false })
        if (error) console.log('error', error)
        else setSips(todos!)
    }
    
    const deleteSip = async (sip: Sip) => {
        const { error } = await supabase.from('sips').delete().match({ id: sip.id })
        if (error) console.log(error.message)
        else {
            setSips(sips.filter((s) => s.id !== sip.id))
        }
    }
    
    return (
        <View style={{ backgroundColor: 'white', height: '100vh', width: '728px', margin: 'auto' }}>
            <Center style={{ height: '100%', margin: 'auto' }}>
                <Heading style={{ padding: '64px' }}>History</Heading>
                <Divider />
                <ScrollView style={{ height: '100%' }}>
                    {sips.map((sip) => (
                        <><Row style={{ padding: '16px' }}>
                        <Text h3 style={tw`font-medium text-black m-auto`}>{(new Date(sip.created_at)).toLocaleString()}</Text><Text h3 style={tw`font-medium text-black m-auto`}>{sip.oz}oz</Text><Button onPress={() => deleteSip(sip)}><Text style={tw`font-medium text-white m-auto`}>Delete</Text></Button>
                      </Row><Divider /></>))}
                </ScrollView>
            </Center>
        </View>
    );
};