import React from 'react';
import { Box } from '.';
import tw from 'twrnc';

const Card = ({ children, width }) => {
  return (
    <Box
      width={width ?? 'xl'}
      style={tw`max-w-sm rounded overflow-hidden shadow-lg`}
    >
      <Box style={tw`px-6 py-4`}>
        {children}
      </Box>
    </Box>
  );
};

export default Card;
