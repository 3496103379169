import React from 'react';
import { extendTheme, NativeBaseProvider, Center, Container } from "native-base";
import { UserContextProvider, useUser } from './UserContext';
import {
  NavigationContainer,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SignInScreen from '../screens/SignIn';

const newColorTheme = {
  brand: {
    900: "#8287af",
    800: "#7c83db",
    700: "#b3bef6",
  },
};
const theme = extendTheme({ colors: newColorTheme });

const Stack = createNativeStackNavigator();

const AuthStack = () => {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Sign In" component={SignInScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const LoggedIn = ({ children }) => {
  const { user } = useUser();
  return user ? children : <AuthStack />;
}

export default function App({ children }) {
  return (
    <UserContextProvider>
      <NativeBaseProvider theme={theme}>
        <LoggedIn>{children}</LoggedIn>
      </NativeBaseProvider>
    </UserContextProvider>
  );
}
