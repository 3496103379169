import React from 'react';
import RingChart from './RingChart';
import { useTheme } from 'native-base';
import * as d3 from 'd3-shape';

const PercentRingChart = (props) => {
    const {
        colors
      } = useTheme();
    return (
        <RingChart
            {...props}
            data={[Math.min(props.data, 1), 1 - Math.min(props.data, 1)]}
            colors={[colors.primary['500'], 'transparent']}
            arcConfigFunction={(d) => d3.arc()({
                startAngle: 0,
                endAngle: d.endAngle - d.startAngle,
                innerRadius: props.width/2-10,
                outerRadius:  props.width/2-10,
                padAngle: 0.03,
                'stroke-linejoin': 'round',
                'stroke-linecap': 'round',
            })}
            pathStyleFunction={(d, i, colors) => ({
                stroke: colors[i] === 'transparent' ? '#ffffff' : colors[i],
                fill: 'none',
                opacity: colors[i] === 'transparent' ? '0.0' : '0.8',
                'stroke-linejoin': 'round',
                'stroke-linecap': 'round',
                'stroke-width': 20,
            })} />
    );
}

export default PercentRingChart;